import cardList from './CardList/config';
import simpleList from './SimpleList/config';
import appbar from './AppBar/config';
import icon from './IconButton/config';
import button from './Button/config';
import toggle from './Toggle/config';
import switchButton from './Switch/config';
import tabNavigator from './TabNavigator/config';
import actionButton from './ActionButton/config';
import line from './Line/config';
import map from './Map/constans/config';
import barCode from './BarCode/config';
import barCodeScanner from './BarcodeScanner/config';
import lineLogin from '@nocode/components/LineLogin/config';
import docomoLogin from '@nocode/components/DocomoLogin/config';
import didLogin from '@nocode/components/DIDLogin/config';
import trustdockLogin from '@nocode/components/TrustdockLogin/config';
import calendar from '@nocode/components/Calendar/config';
import stripePayment from './StripePayment/config';
import stripePaymentSecurity from '@nocode/components/StripePaymentSecurity/config';
import stripeSubscription from './StripeSubscription/config';
import carousel from './Carousel/config';
import appleLogin from './AppleLogin/config';
import horizontalCardList from './HorizontalCardList/config';
import chipList from './ChipList/config';
import avatarList from './AvatarList/config';
import torusLogin from '@nocode/components/TorusLogin/config';
import customHorizontalList from '@nocode/components/CustomHorizontalList/config';
import customButton from '@nocode/components/CustomButton/config';
import RankingList from '@nocode/components/RankingList/config';
import SelectList from '@nocode/components/SelectList/config';
import LivestreamChat from '@nocode/components/LivestreamChat/config';
import ChangeQuantity from '@nocode/components/ChangeQuantity/config';
import InAppPurchase from '@nocode/components/InAppPurchase/config';
import CustomerChat from '@nocode/components/CustomerChat/config';
import univaPay from './UnivaPay/config';
import univaPaySubscription from './UnivaPaySubscription/config';
import QRCode from './QRCode/config';
import fincode from './Fincode/config';
import Blocto from './Blocto/config';
import Timer from './Timer/config';
import GptConversation from './GptConversation/config';

export const config = {
  ActionButton: actionButton,
  AppBar: appbar,
  AppleLogin: appleLogin,
  AvatarList: avatarList,
  BarCode: barCode,
  BarcodeScanner: barCodeScanner,
  Button: button,
  Calendar: calendar,
  CardList: cardList,
  Carousel: carousel,
  ChipList: chipList,
  DIDLogin: didLogin,
  DocomoLogin: docomoLogin,
  QRCode: QRCode,
  HorizontalCardList: horizontalCardList,
  Icon: icon,
  Line: line,
  LineLogin: lineLogin,
  Map: map,
  SimpleList: simpleList,
  StripePayment: stripePayment,
  StripeSubscription: stripeSubscription,
  Switch: switchButton,
  TabNavigator: tabNavigator,
  Toggle: toggle,
  TrustdockLogin: trustdockLogin,
  UnivaPay: univaPay,
  UnivaPaySubscription: univaPaySubscription,
  Fincode: fincode,
  Blocto,
  Timer,
  GptConversation,
  // liverhouse elements
  CustomButton: customButton,
  TorusLogin: torusLogin,
  InAppPurchase,
  StripePaymentSecurity: stripePaymentSecurity,
  LivestreamChat,
  ChangeQuantity,
  RankingList,
  SelectList,
  CustomerChat,
  // end liverhouse elements
};

export default {
  components: [
    cardList,
    simpleList,
    appbar,
    icon,
    button,
    toggle,
    switchButton,
    tabNavigator,
    actionButton,
    line,
    map,
    lineLogin,
    docomoLogin,
    didLogin,
    trustdockLogin,
    calendar,
    stripePayment,
    stripePaymentSecurity,
    stripeSubscription,
    barCode,
    barCodeScanner,
    carousel,
    appleLogin,
    horizontalCardList,
    chipList,
    avatarList,
    torusLogin,
    customHorizontalList,
    customButton,
    RankingList,
    SelectList,
    LivestreamChat,
    ChangeQuantity,
    InAppPurchase,
    CustomerChat,
    univaPay,
    univaPaySubscription,
    QRCode,
    fincode,
    Blocto,
    Timer,
    GptConversation,
  ],
};
