import { appDataBaseSocket } from '@common/configs/app-database-socket';
import { componentName } from '@common/constants/shared';
import { useBloctoAction } from '@common/hooks/useBlocto';
import { fincodeActions } from '@common/hooks/useFincode';
import { useTorusLogin } from '@common/hooks/useTorusLogin';
import { appInfoSelector } from '@common/redux/selectors/app';
import { profileSelector } from '@common/redux/selectors/auth';
import { appLanguageSelector } from '@common/redux/selectors/language';
import { setError } from '@common/redux/slice/app';
import { updateLoggedUserSuccess } from '@common/redux/slice/auth';
import { stripePayment, univaPayPayment } from '@common/services/axiosIntance';
import { ILibraryComponent } from '@common/types/element';
import { onPressAction } from '@common/utils/handleActions/excuteAction';
import {
  customerChatAction,
  handleBuyIap,
  showAlert,
  updateRecurringLoggedInUserDispatch,
} from '@common/utils/handleActions/func/func';
import { get } from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useCustomProp = (props: ILibraryComponent) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { locale } = useSelector(appLanguageSelector);
  const appInfo = useSelector(appInfoSelector);
  const profile = useSelector(profileSelector);

  const auth = {
    ...profile,
    userId: profile?.id,
    databaseId: get(appInfo, 'metadata.auth.tableId'),
  };

  const handleSetError = (res: string) => {
    dispatch(setError(res));
  };

  const getCustomProp = () => {
    switch (props.componentName) {
      // case componentName.STRIPE_PAYMENT_SECURITY: {
      //   return {
      //     auth,
      //     locale,
      //     accountConnectId: appInfo?.accountConnectId,
      //     updateRecordRecordApi: updateLoggedInUserDispatch,
      //     handlePayment: stripePaymentSecurityActionApi,
      //   };
      // }
      case componentName.TORUS_LOGIN: {
        const { loginSuccessWithTorus } = useTorusLogin();
        return { loginSuccessWithTorus, locale };
      }
      case componentName.LIVESTREAM_CHAT: {
        return {
          auth: auth,
          appConfig: {
            appId: appInfo?.id,
            auth: appInfo?.metadata?.auth,
          },
          socketClient: appDataBaseSocket,
        };
      }
      case componentName.IN_APP_PURCHASE: {
        return {
          appId: appInfo?.id,
          profile: auth,
          handleBuyIap,
          updateLoggedUserSuccess,
          handleSetError,
        };
      }
      case componentName.CUSTOMER_CHAT: {
        return {
          customerChatAction,
          showAlert,
          appId: appInfo?.id,
          auth,
        };
      }

      case componentName.STRIPE_PAYMENT:
      case componentName.STRIPE_SUBSCRIPTION: {
        return {
          stripePayment,
          onPressAction,
          paramsPayload: {
            appId: appInfo?.id,
          },
          test_mode: appInfo?.metadata?.stripe,
        };
      }

      case componentName.UNIVAPAY:
      case componentName.UNIVAPAY_SUBSCRIPTION: {
        return {
          univaPayPayment,
          isConnected: appInfo?.isConnectUnivapay,
          paramsPayload: {
            appId: appInfo?.id,
          },
          recurring: profile?.recurring,
          onUpdateRecurringLoggedInUser: updateRecurringLoggedInUserDispatch,
          onPressAction,
          isLoggedInUser: !!profile,
        };
      }
      case componentName.FINCODE: {
        return {
          appid: appInfo?.id,
          isConnected:
            (!!appInfo?.metadata.fincode?.shopId &&
              !!appInfo.metadata.fincode.isVerified) ||
            !!appInfo?.metadata.fincode?.testMode?.shopId,
          auth,
          testModeShopId: appInfo?.metadata.fincode?.testMode?.shopId,
          ...fincodeActions,
        };
      }
      case componentName.BLOCTO: {
        const actions = useBloctoAction();
        return actions;
      }
      case componentName.GPT_CONVERSATION: {
        return {
          appId: appInfo?.appId,
        };
      }
      default:
        return {};
    }
  };

  return {
    loading,
    getCustomProp,
  };
};
